import React, { useContext, useState } from 'react';
import pluralize from 'pluralize';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import ListingCard from 'components/ListingCard';
import MainLayout from 'components/MainLayout';
import FeaturedContext, { FeaturedContextProvider } from './context';
import AddFeatured from './AddFeatured';
import AutoSelection from './AutoSelection';

const FeaturedPage = () => {
  const { itemsCounter, listingItems, setListings, deleteFeaturedListing } = useContext(
    FeaturedContext
  );
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const layout = useLayout();

  const onFeaturedAdded = (listings: Listing[]) => {
    setOpenAddNewModal(false);
    setListings(listings);
  };

  return (
    <MainLayout>
      <StyledFeaturedPage className="featured-section">
        <div className="featured-section__wrapper">
          <div className="featured-section__header">
            <h4 className="featured-section__title">Site Administration</h4>
            <h4 className="featured-section__featured-title">Featured Listings</h4>
          </div>
          <AutoSelection className="featured-section__filters" />
          <h4 className="featured-section__title">Manual Selection</h4>
          <div className="featured-section__items-controls">
            <div className="featured-section__items-counter">
              {itemsCounter} {pluralize('item', itemsCounter)}
            </div>
            {layout === 'desktop' && (
              <Button
                className="featured-section__add-new"
                onClick={() => setOpenAddNewModal(true)}>
                Add new
              </Button>
            )}
            {(layout === 'tablet' || layout === 'mobile') && (
              <Button
                className="featured-section__add-new"
                onClick={() => setOpenAddNewModal(true)}>
                <Icon name="plus" />
              </Button>
            )}
          </div>
          <div className="featured-list">
            {listingItems.map(item => (
              <ListingCard key={item.id} data={item} onDelete={deleteFeaturedListing} />
            ))}
          </div>
        </div>
        <StyledModal
          className="add-new-modal"
          open={openAddNewModal}
          onClose={() => setOpenAddNewModal(false)}
          modalTitle="Add featured">
          <AddFeatured onAdded={onFeaturedAdded} />
        </StyledModal>
      </StyledFeaturedPage>
    </MainLayout>
  );
};

export default props => (
  <FeaturedContextProvider>
    <FeaturedPage {...props} />
  </FeaturedContextProvider>
);

const StyledFeaturedPage = styled.div`
  display: flex;
  justify-content: center;

  .featured-section {
    &__wrapper {
      flex: 1;
      width: 100%;
    }
    &__header {
      margin: 0 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__filters {
      margin-bottom: 32px;
    }
    &__items-controls {
      margin: 20px 0 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__items-counter {
      font-size: 14px;
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: ${props => props.theme.colors.black};
      margin: 0;
    }
    &__header .featured-section__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
    }

    &__featured-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.black};
      margin: 0;
      border-bottom: 2px solid ${props => props.theme.colors.red};
    }
    &__add-new {
      min-width: 100px;
    }
  }
  .featured-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(237px, 267px));
    grid-gap: 16px;
    max-width: 1334px;
    .property-card {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .featured-list {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
    }
    .featured-section {
      &__add-new {
        min-width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0;
    .featured-list {
      grid-template-columns: 1fr;
    }
    .featured-section {
      &__header {
        margin: 0 0 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &__title {
        margin: 0 0 8px;
      }
      &__items-controls {
        margin-top: 16px;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    min-width: 888px;
    min-height: 500px;
    max-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 12px;
  }
  .modal-body {
    display: flex;
    flex: 1;
    flex-flow: column;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .modal-content {
      min-width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .modal-bg {
      padding: 0;
    }
    .modal-body {
      position: relative;
    }
    .modal-content {
      height: 100%;
      top: 0;
      left: 0;
      padding: 10px 10px 52px;
    }
  }
`;
