import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import { getListingAddress } from 'utils/mapping';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import Image from 'components/Image';
import ListingCarousel from 'components/ListingCarousel';
import noImage from 'assets/images/placeholder_no_image.png';

export interface ListingCardProps extends React.HTMLAttributes<HTMLDivElement> {
  compact?: boolean;
  data: Listing;
  onDelete?: (id: string) => void;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const numberFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 });

const ListingCard: React.FC<ListingCardProps> = ({ className, compact, data, onDelete }) => {
  const photos = Array.from(new Set(data.images));

  const renderArrowButton = (type: 'prev' | 'next') => (
    clickHandler: () => void,
    hasPrev: boolean
  ) => {
    if (!hasPrev) return null;

    const handleClick = e => {
      e.preventDefault();
      clickHandler();
    };

    return (
      <StyledCarouselButton onClick={handleClick} className={clsx('carousel-button', type)}>
        <Icon name="chevron" style={{ transform: `rotate(${type === 'prev' ? 90 : -90}deg)` }} />
      </StyledCarouselButton>
    );
  };

  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    _index: number,
    label: string
  ) => {
    const handleClick = e => {
      e.preventDefault();
      clickHandler(e);
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <li
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        aria-label={label}
        onClick={handleClick}
        className={clsx('dot', { selected: isSelected })}
      />
    );
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    //  <Wrapper {...props}>
    <StyledListingCard
      className={clsx('property-card', { compact }, className)}
      href={data.url}
      target="_blank"
      title={data.address1}>
      <Button
        className={clsx('delete-button')}
        onClick={e => {
          e.preventDefault();
          setOpenDeleteModal(true);
        }}>
        <Icon name="delete" />
      </Button>
      {photos.length > 0 ? (
        <ListingCarousel
          images={photos}
          showStatus={false}
          showThumbs={false}
          renderIndicator={renderIndicator}
          renderArrowPrev={renderArrowButton('prev')}
          renderArrowNext={renderArrowButton('next')}
          renderImage={(item, idx) => (
            <Image cloudFront={item} size="400x300" key={item} alt={`photo-${idx}`} />
          )}
          showIndicators={photos.length > 1}
          className="property-card__carousel"
        />
      ) : (
        <img src={noImage} className="img-error" alt="img-error" />
      )}

      <div className="property-card__info">
        <div className="property-card__info-line price">
          {currencyFormatter.format(data.currentPrice)}{' '}
          {/* <span className="property-card__new-tag">New</span> */}
        </div>
        <div className="property-card__info-line">
          {data.propertyDetails.beds} Bd · {data.propertyDetails.baths} Ba ·{' '}
          {numberFormatter.format(data.propertyDetails.sqft)} ft
          <sup>2</sup>
        </div>
        <div className="property-card__info-line">{data.address1}</div>
        {/* <div className="property-card__info-line">{data.address2}</div> */}
        <div className="property-card__info-line">{getListingAddress(data)}</div>
      </div>
      <StyledModal
        className="delete-modal"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        modalTitle="Remove from Featured">
        <p className="delete-modal__delete-text">
          Are you sure you want to remove this listing? This action can’t be undone.
        </p>
        <StyledButton
          className="delete-modal__discard-btn"
          secondary
          onClick={() => setOpenDeleteModal(false)}>
          Discard
        </StyledButton>
        <StyledButton
          className="delete-modal__remove-btn"
          onClick={() => {
            if (onDelete) onDelete(data.id);
            setOpenDeleteModal(false);
          }}>
          Remove
        </StyledButton>
      </StyledModal>
    </StyledListingCard>
    //  </Wrapper>
  );
};

export default ListingCard;

const StyledListingCard = styled.a<React.HTMLAttributes<HTMLAnchorElement>>`
  width: 320px;
  height: 276px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  color: inherit;
  text-decoration: none;

  &.compact {
    width: 200px;
    height: 232px;
    padding: 8px;

    .property-card__carpousel,
    .property-card__photo,
    .img-error {
      height: 112px;
      border-radius: 3px;
      margin-bottom: 8px;
    }

    .property-card__info {
      padding: 0;
    }
  }
  .carousel-button {
    opacity: 0;
  }

  &:hover {
    .carousel-button {
      opacity: 1;
    }
  }

  .property-card__new-tag {
    background: ${props => props.theme.colors.violet};
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    border-radius: 3px;
    padding: 2px 4px;
    margin-left: 8px;
    vertical-align: middle;
  }

  .delete-button {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: ${props => props.theme.transitions.standart};

    .icon {
      fill: #fff;
    }

    &.saved {
      background: ${props => props.theme.colors.violet};
    }
    &:hover:not(.saved) {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .property-card__photo,
  .img-error {
    width: 100%;
    height: 155px;
    border-radius: 3px 3px 0 0;
    object-fit: cover;
  }

  .img-error {
    background: ${props => props.theme.colors.harborGray};
  }

  .property-card__carousel {
    width: 100%;
    height: 155px;
    border-radius: 3px 3px;

    .carousel {
      height: 100%;

      .slide {
        background: ${props => props.theme.colors.harborGray};
      }

      .slider-wrapper,
      .slider,
      img {
        height: 100%;
      }

      img {
        object-fit: cover;
      }

      .dot {
        margin: 0 2px;
        box-shadow: none;
        background: rgba(255, 255, 255, 0.4);
        outline: none;

        &.selected {
          background: #fff;
        }
      }
    }
  }

  .property-card__info {
    padding: 12px;
    font-size: 14px;
    line-height: 24px;

    .price {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const StyledCarouselButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.standart};
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &.prev {
    top: 50%;
    left: 12px;
  }
  &.next {
    top: 50%;
    right: 12px;
  }

  .icon {
    fill: #fff;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    max-width: 324px;
  }
  .delete-modal {
    &__delete-text {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  &.delete-modal__discard-btn {
    margin: 0 0 16px;
  }
`;

// const StyledLink = styled.a`
//   color: inherit; /* blue colors for links too */
//   text-decoration: inherit; /* no underline */
//   cursor: pointer;
// `;
