import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Icon, { IconName } from 'components/Icon';
import PopMenu from 'components/PopMenu';
import { Placement } from '@popperjs/core';

export type DropdownOption = {
  value: any;
  text: React.ReactNode;
  displayText?: string;
  disabled?: boolean;
  search?: string;
  label?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export interface DropdownProps extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the label.
   */
  label?: React.ReactNode;
  /**
   * Error state. If used as string error text will appear below the input.
   */
  error?: boolean | React.ReactNode;
  /**
   * Success state. Makes icon green and adds default 'check-circle' icon.
   */
  success?: boolean;
  /**
   * Name of an icon to dispay on the right side of the input.
   */
  icon?: IconName;
  /**
   * Displayed text
   */
  value?: any;
  /**
   * Makes dropdown searchable.
   */
  search?: boolean;
  /**
   * Display selected options tags (used with 'multiple').
   */
  tags?: boolean;
  /**
   * Disables options selection.
   */
  disabled?: boolean;
  /**
   * Optional text if no option is selected.
   */
  placeholder?: string;
  /**
   * Optional text if no option is selected.
   */
  menuPlacement?: Placement;
  /**
   * Use display: none to hide content
   */
  noUnmount?: boolean;
  /*
   ** Attribute for auto tests
   */
  'data-cy'?: string;
  /**
   * Overrides default option onClick handler.
   */
  onChange?: (value) => void;
}

const DropdownMenu: React.FC<DropdownProps> = ({
  label,
  error,
  success,
  icon = 'chevron',
  value,
  search,
  disabled,
  placeholder,
  menuPlacement,
  noUnmount,
  className,
  children,
  'data-cy': dataCY,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleMenuOutsideClick = e => {
    if (!containerRef.current?.contains(e.target)) setOpen(false);
  };

  return (
    <StyledDropdownMenu
      ref={containerRef}
      className={clsx(
        'dropdown-menu',
        {
          open,
          label,
          icon,
          success,
          disabled,
          search,
          error: error && !open,
          placeholder: !value
        },
        className
      )}>
      <button
        className="dropdown-menu__button"
        type="button"
        onClick={() => setOpen(v => !v)}
        data-cy={dataCY}
        {...props}>
        <span className="label">{label}</span>{' '}
        <span className="inner-text">{value || placeholder}</span> <Icon name="chevron" />
      </button>
      <StyledPopMenu
        open={open}
        placement={menuPlacement}
        target={containerRef.current}
        onOutsideClick={handleMenuOutsideClick}
        className={clsx('dropdown-menu-content', className)}
        modifiers={[
          {
            name: 'offset',
            options: { offset: [0, 4] }
          }
        ]}>
        {children}
      </StyledPopMenu>
    </StyledDropdownMenu>
  );
};

export default React.memo(DropdownMenu);

const StyledDropdownMenu = styled.div`
  position: relative;
  display: inline-block;

  .dropdown-menu__button {
    display: flex;
    width: 100%;
    /* align-items: center; */
    height: 32px;
    padding: 8px;
    background: #fff;
    border-radius: ${props => props.theme.misc.borderRadius};
    border: 1px solid #000000;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    outline: none;

    &:hover {
      border-color: ${props => props.theme.colors.red};
    }

    .label {
      color: ${props => props.theme.colors.grayDark};
    }

    .inner-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 15px;
      margin: 0 4px;
    }

    & > .icon-chevron {
      margin-left: auto;
      flex-shrink: 0;
    }
  }

  &.open .dropdown-menu__button > .icon-chevron {
    transform: rotate(180deg);
    fill: ${props => props.theme.colors.red};
  }

  &.placeholder .inner-text {
    color: #8b8b8b;
    font-weight: 600;
  }
`;

const StyledPopMenu = styled(PopMenu)`
  background: #fff;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);
  border-radius: ${props => props.theme.misc.borderRadius};
  padding: 12px;
  width: 100%;
`;
