import React from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import { Props as CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Image from 'components/Image';
import noImage from 'assets/images/placeholder_no_image_large.png';
import { IMAGE_SIZES } from '../../consts';

interface ListingCarouselProps extends Partial<CarouselProps> {
  images: string[];
  className?: string;
  renderImage?: (image: string, idx: number) => JSX.Element;
}

const ListingCarousel: React.FC<ListingCarouselProps> = ({
  images,
  className,
  renderImage: renderImageProp,
  ...props
}) => {
  const isMobile = useLayout() === 'mobile';

  const renderThumbs = () => {
    const thumbs = images.map((image, idx) => (
      <Image
        cloudFront={image}
        size={IMAGE_SIZES.small}
        error={noImage}
        className="listing-carousel__thumb"
        key={image}
        alt={`photo-${idx}`}
        data-cy={`small-${image}`}
      />
    ));

    return thumbs;
  };

  const renderImage =
    renderImageProp ||
    ((item, idx) => {
      return (
        <Image
          error={placeholder}
          cloudFront={{ size: IMAGE_SIZES.large, key: item }}
          className="listing-carousel__slide"
          key={item}
          alt={`photo-${idx}`}
          data-cy={`large-${item}`}
        />
      );
    });

  return (
    <StyledCarousel
      className={clsx('listing-carousel', className)}
      showStatus={false}
      showArrows
      showIndicators={isMobile}
      showThumbs={!isMobile && images.length > 1}
      renderThumbs={renderThumbs}
      thumbWidth={70}
      data-cy="listing-carousel"
      {...props}>
      {images.map(renderImage)}
    </StyledCarousel>
  );
};

export default React.memo(ListingCarousel);

const StyledCarousel = styled(Carousel)`
  outline: none;
  overflow: hidden;
  background: #000;
  height: 100%;
  display: flex;
  flex-direction: column;

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
  }

  .carousel {
    position: relative;
    width: 100%;

    * {
      box-sizing: border-box;
    }

    img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }

    &.carousel-slider {
      position: relative;
      margin: 0;
      overflow: hidden;
      height: 100%;

      .control-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        font-size: 26px;
        bottom: 0;
        margin: 0 10px;
        padding: 5px;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
      }

      .slider-wrapper {
        overflow: hidden;
        margin: auto;
        width: 100%;
        height: 100%;
        transition: height 0.15s ease-in;

        .slider {
          margin: 0;
          padding: 0;
          position: relative;
          list-style: none;
          width: 100%;
          height: 100%;

          &.animated {
            transition: all 0.35s ease-in-out;
          }

          .slide {
            min-width: 100%;
            height: 100%;
            margin: 0;
            position: relative;
            text-align: center;
            background: #000;

            img {
              width: 100%;
              height: 100%;
              vertical-align: top;
              border: 0;
              object-fit: cover;
            }

            iframe {
              display: inline-block;
              width: calc(100% - 80px);
              margin: 0 40px 40px;
              border: 0;
            }
          }

          .legend {
            transition: all 0.5s ease-in-out;
            position: absolute;
            bottom: 40px;
            left: 50%;
            margin-left: -45%;
            width: 90%;
            border-radius: 10px;
            background: #000;
            color: #fff;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            opacity: 0.25;
          }
        }

        &.axis-horizontal .slider {
          display: flex;

          .slide {
            flex-direction: column;
            flex-flow: column;
          }
        }

        &.axis-vertical {
          display: flex;

          .slider {
            flex-direction: column;
          }
        }
      }
    }

    .control-arrow {
      outline: 0;
      border: 0;
      background: none;
      top: 0;
      width: 32px;
      height: 100%;
      background: #000;
      font-size: 32px;
      border: 1px solid #fff;

      &.control-disabled {
        display: none;
      }
      &.control-prev {
        left: -1px;
        &::before {
          border-right: 8px solid #fff;
        }
        &::after {
          content: '‹';
          color: #fff;
          text-shadow: 1px 1px 10px #000;
        }
      }
      &.control-next {
        right: -1px;
        &::before {
          border-left: 8px solid #fff;
        }
        &::after {
          content: '›';
          color: #fff;
          text-shadow: 0 0 4px #000;
        }
      }
    }

    .thumbs-wrapper {
      overflow: hidden;
      border-top: 1px solid #fff;

      .thumbs {
        box-sizing: border-box;
        display: flex;
        transition: all 0.15s ease-in;
        transform: translate3d(0, 0, 0);
        position: relative;
        list-style: none;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        height: 70px;

        .thumb {
          box-sizing: border-box;
          transition: border 0.15s ease-in;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          border: 1px solid #fff;
          border-width: 0 1px;
          width: 70px !important;
          height: 70px;
          flex-shrink: 0;
          outline: none;
          cursor: pointer;

          &.selected {
            opacity: 0.7;
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .control-dots {
      position: absolute;
      bottom: 0;
      margin: 8px 0;
      padding: 0;
      text-align: center;
      width: 100%;

      .dot {
        transition: opacity 0.25s ease-in;
        opacity: 0.3;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
        background: #fff;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        cursor: pointer;
        display: inline-block;
        margin: 0 2px;

        &.selected,
        &:hover {
          opacity: 1;
        }
      }
    }

    .carousel-status {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      font-size: 10px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
      color: #fff;
    }

    &:hover .slide .legend {
      opacity: 1;
    }
  }
`;
