import React, { useEffect, useRef } from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import { styled } from 'styles';
import clsx from 'clsx';

const renderTrack = (props, state) => {
  return <StyledTrack index={state.index} multipleValues={Array.isArray(state.value)} {...props} />;
};
const renderThumb = props => <StyledThumb {...props} />;

const Slider: React.FC<ReactSliderProps> = ({ className, ...props }) => {
  const sliderRef: any = useRef(null);

  useEffect(() => {
    if (sliderRef.current === null) return;

    const handleResize = () => {
      if (sliderRef.current.slider.offsetParent !== null) {
        sliderRef.current.resize();
      }
    };

    const ro = new ResizeObserver(handleResize);
    ro.observe(sliderRef.current.slider);

    // eslint-disable-next-line consistent-return
    return () => {
      ro.disconnect();
    };
  }, []);

  return (
    <StyledSlider
      ref={sliderRef}
      renderTrack={renderTrack}
      renderThumb={renderThumb}
      className={clsx('slider', className)}
      {...props}
    />
  );
};

export default React.memo(Slider);

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 12px;
`;

const StyledThumb = styled.div`
  width: 12px;
  height: 12px;
  text-align: center;
  background: ${props => props.theme.colors.red};
  border-radius: 50%;
  outline: none;
  cursor: grab;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 50%;
  }

  &.thumb-1 {
    z-index: 2 !important;
  }
`;

const StyledTrack = styled.div<{ index: number; multipleValues: boolean }>`
  bottom: 5px;
  height: 2px;
  background: ${props => (props.index === 1 && props.multipleValues ? '#FF7272' : '#E7E7E7')};
`;
