import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import notify from 'notify';
import { styled } from 'styles';
import Button from 'components/Button';
import Filters from './Filters';
import FeaturedContext from './context';
import { clearFilters as clearFiltersRequest } from './api';

const formInit = { listingTypes: [] };

const AutoSelection = props => {
  const methods = useForm();
  const { reset } = methods;
  const {
    autoSelectionFilters,
    filteredListingsCount,
    updateFilters,
    setFilteredListingsCount
  } = useContext(FeaturedContext);

  const clearFilters = async () => {
    try {
      const newCount = await clearFiltersRequest();
      setFilteredListingsCount(newCount);
      reset(formInit);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Error: clearFilters');
      notify(err.message);
    }
  };

  useEffect(() => {
    if (autoSelectionFilters) reset(autoSelectionFilters);
  }, [autoSelectionFilters, reset]);

  return (
    <StyledAutoSelection {...props}>
      <h5 className="featured-section__title">Auto Selection</h5>
      <Button className="clear-filters-button" secondary onClick={clearFilters}>
        ↺ Clear Filters
      </Button>
      <FormProvider {...methods}>
        <Filters onSubmit={updateFilters} className="filters" />
      </FormProvider>

      <div className="results-counter">
        {filteredListingsCount && `${filteredListingsCount} results`}
      </div>
    </StyledAutoSelection>
  );
};

export default AutoSelection;

const StyledAutoSelection = styled.div`
  background: #fafafa;
  padding: 16px;
  width: 100%;

  .featured-section__title {
    display: inline-block;
    line-height: 24px;
    margin-bottom: 28px !important;
  }

  .clear-filters-button {
    float: right;
  }

  .form-placeholder {
    height: 32px;
  }

  .filters {
    display: flex;
    width: 100%;

    .dropdown-menu,
    .dropdown {
      margin-right: 12px;
      min-width: 120px;
      max-width: 240px;

      &.price .dropdown-menu-content {
        width: 248px;

        .inputs {
          display: flex;
          justify-content: space-between;

          .input {
            min-width: auto;
            width: 96px;
          }
        }
      }

      &.postal-code .dropdown-menu-content {
        width: 260px;

        .hint {
          font-size: 10px;
          color: ${props => props.theme.colors.grayDark};
          margin-bottom: 10px;
        }

        .input {
          width: 100%;
        }
      }
    }
  }

  .results-counter {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .filters {
      flex-direction: column;

      .dropdown-menu,
      .dropdown {
        margin: 0 0 16px 0;
        max-width: unset;
      }
    }
  }
`;
